import betweenDate from '../../../../../mixins/betweenDate';
import {mapActions, mapGetters} from 'vuex';
import {get, set} from "../../../../../helpers/cookie";

export default {
    data() {
        return {
            isShow: false,
            isSegment: false,
        };
    },
    computed: {
        ...mapGetters({
            segment: 'cabinet/segment',
        })
    },
    mixins: [betweenDate],
    name: 'Notice',
    mounted() {
		this.isSegment = Boolean(['smb0', 'smb1-2', 'smb3+', 'b2cAutoDealer', 'b2cAutoSalon', 'b2cAutoSale', 'b2cAutoOrder_calls', 'b2cAutoDealer_comp_exp', 'b2cAutoDisassemble'].indexOf(this.segment) >= 0);
		this.setTime('2022-05-06 00:00:01', '2022-05-31 23:59:59');
        if ((this.showBetweenDateTime || Number(get('increased_refill_bonus'))) && this.isSegment) {
            this.isShow = true;
        }
    },
    i18n: {
        messages: {
            uk: {
                'Публікуйте і піднімайте в ТОП з вигодою': 'Публікуйте і піднімайте в ТОП з вигодою',
                'Поповніть Особистий рахунок, щоб отримати додаткову вигоду': 'Поповніть Особистий рахунок, щоб отримати додаткову вигоду',
                'Детальніше': 'Детальніше',
            },
            ru: {
                'Публікуйте і піднімайте в ТОП з вигодою': 'Публикуйте и поднимайте в ТОП с удобством',
                'Поповніть Особистий рахунок, щоб отримати додаткову вигоду': 'Пополните Личный счет, чтобы получить дополнительную выгоду',
                'Детальніше': 'Подробнее',
            }
        }
    }
};
