import {mapGetters, mapActions} from 'vuex';

export default {
	data: function () {
		return {
			checked: false,
			paid: undefined,
			publicationInfo: {},
			ableToUseFreePublication: false,
			isLoading: true,
		};
	},
	props: ['id', 'data', 'usedPublications', 'valueArchiveSelect', 'isChekedFromURL', 'virtualFreePublication', 'segment'],
	created() {
		this.paid = !this._result(this.data, 'publication.expiredAt') || new Date().getTime() > Number(this._result(this.data, 'publication.expiredAt'));
		this.checked = this.isChekedFromURL;
	},
	watch: {
		data() {
			this.resetComponent();
			this.paid = !this._result(this.data, 'publication.expiredAt') || new Date().getTime() > Number(this._result(this.data, 'publication.expiredAt'));
			this.checked = this.isChekedFromURL;
			const _this = this;
			this.getPublicationInfo(this.id)
				.then((info) => {
					_this.publicationInfo = info;
					_this.ableToUseFreePublication = Boolean(info.publications);
					_this.isLoading = false;
				});
		},
		checked(newValue) {
			if (this.paid) {
				this.$emit('onChangeAdsFrom', {
					id: this.id,
					publications: Number(newValue),
					autoInfo: this.publicationInfo
				});
			} else {
				this.$emit('onChangeAdsFrom', {
					id: this.id,
					republications: Number(newValue),
					autoInfo: this.publicationInfo
				});
			}
		},
		valueArchiveSelect(newValue) {
			if (!['DELETED_BY_ADMIN'].includes(this._result(this.data, 'status'))) {
				this.checked = newValue;
			}
		}
	},
	methods: {
		...mapActions({
			getPublicationInfo: 'cabinet/constants/getPublicationInfo',
		}),
		resetComponent() {
			this.checked = false;
		},
		getPublicationPrice() {
			if (this.ableToUseFreePublication && this.virtualFreePublication) {
				return this.publicationInfo.currentPrice;
			} else {
				return this.publicationInfo.basePrice;
			}
		},
		canBeRestored() {
			return !['DELETED_BY_ADMIN'].includes(this.data?.status)
				|| (
					this.data?.deleteReason?.reason === 87
					&& ['b2cAutoDealer', 'b2cAutoOrder'].includes(this.segment)
				);
		},
  },
  computed: {
    ...mapGetters({
      prices: 'cabinet/constants/prices',
    }),
    publicationPeriodMsg() {
      return this.publicationInfo?.options?.publicationDays
        ? this.$tc("день", this.publicationInfo.options.publicationDays)
        : `1 ${this.$t('місяць')}`;
		}
	},
	i18n: {
		messages: {
			uk: {
				'Удалено модератором': 'Видалено модератором',
				'Повторная публикация на': 'Повторна публікація на',
				'месяц': 'місяць',
				'Публикация до': 'Публікація до',
				'бесплатно': 'безкоштовно',
				'Будет использована бесплатная публикация': 'Буде використана безкоштовна публікація',
				'день': '0 днів | {count} день | {count} дні | {count} днів',
			},
			ru: {
				'Удалено модератором': 'Удалено модератором',
				'Повторная публикация на': 'Повторная публикация на',
				'месяц': 'месяц',
				'Публикация до': 'Публикация до',
				'бесплатно': 'бесплатно',
				'Будет использована бесплатная публикация': 'Будет использована бесплатная публикация',
				'день': '0 дней | {count} день | {count} дня | {count} дней',
			}
		}
	}
};
