import { mapGetters, mapActions } from 'vuex';

const DEFAULT_DAY_PER_MONTH = 30;

export default {
  data() {
    return {
      total: 0,
      top: undefined,
      promoTop: undefined,
      selectedDay: this.massSelectedDay,
      publications: 0,
      checked: false,
      selectedDayInput: '',
      position: 0,
      loadedPosition: false,
      onScreen: false,
      changeMassTOP: false,
      changeMassPublications: false,
      currentPosition: undefined,
    };
  },
  mixins: [require('../../../../../mixins/humanize')],
  props: ['id', 'data', 'usedPublications', 'periodDefaultOptions', 'massTop', 'massSelectedDay', 'massPublications', 'checkBoxSelectAll', 'isChekedFromURL', 'reset', 'virtualFreePublication'],
  created() {
    this.selectedDayCalculate();
    this.checked = this.isChekedFromURL;
  },
  mounted() {
    const _this = this;
    try {
      const IOW = require('../../../../../helpers/IntersectionObserver_screen__01');
      IOW.add(this.$el, (entry = {}) => {
        _this.onScreen = Boolean(entry.isIntersecting);
      });
    } catch (error) {
      _this.onScreen = true;
      console.error(error);
    }
  },
  methods: {
    ...mapActions({
      forecastPosition: 'cabinet/advertisements/manage/forecastPosition',
      getCurrentPosition: 'cabinet/advertisements/manage/getCurrentPosition',
    }),
    /**
		 * Прогнозирует позицую в поиске
		 */
    calculatePosition() {
      this.forecastPosition({
        top: this.top,
        autoId: this.id,
        categoryId: this._result(this.data, 'category.id'),
        brandId: this._result(this.data, 'brand.id'),
        modelId: this._result(this.data, 'model.id'),
        custom: this._result(this.data, 'custom'),
        abroad: this._result(this.data, 'abroad'),
      }).then((data) => {
        this.position = data.position;
        this.loadedPosition = false;
      });
    },
    getPublicationPrice() {
      if (this.ableToUseFreePublication && this.virtualFreePublication) {
        return Number(this.publications) * !(this.usedPublications) * this.publicationInfo?.currentPrice;
      }
      return Number(this.publications) * this.publicationInfo?.basePrice;
    },
    calculateCurrentPosition() {
      this.getCurrentPosition({
        autoId: this.id,
        categoryId: this._result(this.data, 'category.id'),
        brandId: this._result(this.data, 'brand.id'),
        modelId: this._result(this.data, 'model.id'),
        custom: this._result(this.data, 'custom'),
        abroad: this._result(this.data, 'abroad'),
      }).then((data) => {
        this.currentPosition = this.humanizeNumber(data.position);
      });
    },
    /**
		 * Для обнуления компоненты
		 */
    resetComponent() {
      this.total = 0;
      this.top = undefined;
      this.publications = 0;
      this.checked = false;
      this.currentPosition = undefined;
    },
    /**
		 * Емит в родительскую компоненту данных
		 */
    changeAdsFrom() {
      setTimeout(() => {
        this.$emit('onChangeAdsFrom', {
          id: this.id,
          top: Number(this.top) * Number(this.checked) || 0,
          selectedDay: Number(this.selectedDay) * Number(this.checked),
          publications: Number(this.publications) * Number(this.checked),
          autoInfo: this.publicationInfo,
        });
      }, 500);
    },
    topCalculate(action) {
      this.top = this.top ? this.top : 0;
      switch (action) {
        case 'increment':
          this.top++;
          break;
        case 'decrement':
          this.top--;
          this.top = this.top < 0 ? 0 : this.top;
          break;
      }
      this.calculatePosition();
    },
    /**
		 * пересчет(парс) дней в псевдоселекте
		 * @param action
		 */
    selectedDayCalculate(action) {
      let recognizedInput;
      switch (action) {
        case 'massAction':
          break;
        case 'increment':
          this.selectedDay++;
          break;
        case 'decrement':
          this.selectedDay--;
          break;
        case 'parse':
          // eslint-disable-next-line no-case-declarations
          recognizedInput = this.selectedDayInput.match(/([0-9])/ig);
          if (recognizedInput) {
            this.selectedDay = recognizedInput.join('');
          }
          break;
        default:
          break;
      }
      this.selectedDayValidation();
      this.selectedDayInput = `${this.$tc('день', this.selectedDay)}`;
    },
    /**
		 * Валидация количества дней
		 */
    selectedDayValidation() {
      if (this.selectedDay < 1) {
        this.selectedDay = 1;
      } else if (this.selectedDay > 60) {
        this.selectedDay = 60;
      }
    },
  },
  computed: {
    ...mapGetters({
      prices: 'cabinet/constants/prices',
    }),
    pubDaysLeft() {
      const { m, _result, data } = this;
      return Math.round(
        m(_result(data, 'publication.expiredAt')).diff(m(), 'days', true),
      );
    },
    publicationInfo() {
      return this.data?.prices;
    },
    ableToUseFreePublication() {
      return Boolean(this.data?.prices?.publications);
    },
    mainPhoto() {
      if (this._result(this.data, 'photos.main.id')) {
        return `https://cdn3.riastatic.com/photosnew/auto/photo/${this.data.brand.eng}_${this.data.model.eng}__${this.data.photos.main.id}s.jpg`;
      }
      return '';
    },
    totalPrice() {
      return Math.floor((Number(this.top || 0) * Number(this.selectedDay) * Number(this.publicationInfo?.levelPrice) + this.getPublicationPrice() || 0) * Number(this.checked));
    },
  },
  watch: {
    onScreen(newValue) {
      if (this.data && !this.currentPosition) {
        this.calculateCurrentPosition();
      }
      if (this.changeMassTOP && newValue && this.top) {
        this.calculatePosition();
        this.changeMassTOP = false;
      }
    },
    // перетираем данные компоненты при паганации
    data() {
      if (this.onScreen) {
        this.calculateCurrentPosition();
      }
      this.resetComponent();
      this.checked = this.isChekedFromURL;
    },
    checkBoxSelectAll(newValue) {
      this.checked = Boolean(newValue);
    },
    top(newValue) {
      switch (true) {
        case Number(newValue) <= 0:
          this.checked = Boolean(this.publications > 0);
          this.top = 0;
          break;
        case Number(newValue) > 3000:
          this.checked = true;
          this.loadedPosition = true;
          this.top = 3000;
          break;
        default:
          this.checked = true;
          this.loadedPosition = true;
          break;
      }
    },
    totalPrice() {
      this.changeAdsFrom();
    },
    publications(newValue) {
      // если выбраны услуги - вешаем галочку
      if (!this.changeMassPublications) {
        this.checked = Number(this.top) > 0 || Number(newValue) > 0;
      } else {
        this.changeMassPublications = false;
      }
    },
    reset(newValue) {
      if (newValue) {
        this.checked = false;
      }
    },
    /**
		 * Слушатель ТОПов с массовых действий
		 * @param newValue
		 */
    massTop(newValue) {
      if (newValue) {
        this.changeMassTOP = true;
      }
      this.top = newValue;
      if (this.onScreen) {
        this.calculatePosition();
      }
    },
    /**
		 * Слушатель периода для ТОПов с массовых действий
		 * @param newValue
		 */
    massSelectedDay(newValue) {
      this.selectedDay = newValue;
      this.selectedDayCalculate('massAction');
    },
    /**
		 * Слушатель кол-ва публикаций с массовых действий
		 * @param newValue
		 */
    massPublications(newValue) {
      this.changeMassPublications = true;
      this.publications = newValue;
      this.checked = Boolean(newValue > 0) || Number(this.top) > 0;
    },
    selectedDay(value) {
      const { periodDefaultOptions, pubDaysLeft } = this;
      const monthMultiplier = DEFAULT_DAY_PER_MONTH * this.publications;
      const daysLeft = pubDaysLeft + monthMultiplier;

      if (daysLeft < value) {
        const nextMonth = this.publications + 1;
        if (periodDefaultOptions.includes(nextMonth)) {
          this.publications = nextMonth;
        }
      } else if (value <= (daysLeft - DEFAULT_DAY_PER_MONTH)) {
        const prevMonth = this.publications - 1;

        if (periodDefaultOptions.includes(prevMonth) || prevMonth === 0) {
          this.publications = prevMonth;
        }
      }
    },
  },
  i18n: {
    messages: {
      uk: {
        день: '0 днів | {count} день | {count} дні | {count} днів',
        'публикация до': 'публікація до',
        от: 'від',
        активный: 'активний',
        'в очереди': 'у черзі',
        'Период публикации': 'Період публікації',
        дней: 'днів',
        Уровень: 'Рівень',
        Период: 'Період',
        'Вы выбрали': 'Ви вибрали',
        уровня: 'рівня',
        Это: 'Це',
        'позиция в поиске': 'позиція у пошуку',
        'Будет использована бесплатная публикация': 'Буде використана безкоштовна публікація',
        поиске: 'пошуку',
        Создано: 'Создано',
      },
      ru: {
        день: '0 дней | {count} день | {count} дня | {count} дней',
        'публикация до': 'публикация до',
        от: 'от',
        активный: 'активный',
        'в очереди': 'в очереди',
        'Период публикации': 'Период публикации',
        дней: 'дней',
        Уровень: 'Уровень',
        Период: 'Период',
        'Вы выбрали': 'Вы выбрали',
        уровня: 'уровня',
        Это: 'Это',
        'позиция в поиске': 'позиция в поиске',
        'Будет использована бесплатная публикация': 'Будет использована бесплатная публикация',
        поиске: 'поиске',
        Создано: 'Створене',
      },
    },
  },
};
